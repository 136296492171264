<template>
  <div 
    id="payments"
    class="page"
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="3"
      color="secondary darken-3"
    />

    <div class="page-content pb-16"
      v-if="payments.length>0"
    >
      <v-simple-table
        class="items transparent text-left px-4"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th width="64" class="pl-0">
                Data
              </th>
              <th class="pl-2">
                Pagamento
              </th>
              <th width="80" class="pr-0">
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, n) in list"
              :key="n"
            >
              <td class="overline pl-0">
                {{ item.date | formatDate }}
              </td>
              <td class="pl-2 text-truncate">
                {{ formatType(item) }}
              </td>
              <td class="text-no-wrap font-weight-bold pr-0">
                {{ item.value }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="total primary--text pa-4 d-flex justify-space-between">
        <div class="card-background background" />
        <div class="text-body-2">
          💰 &nbsp; Total Pagamentos:
        </div>
        <div class="text-body-2 font-weight-bold">
          {{ payments | paymentsTotal }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty-state d-flex flex-column justify-center fill-height text-center px-8 pb-8" 
    >
      <img 
        class="empty-icon mx-auto" 
        src="/img/empty-payments.svg"
      >
      <h4 class="title text-h6 mt-12">
        Extrato de Pagamentos
      </h4>
      <p class="text body-2 text--secondary my-6">
        Nossas transferências vão aparecer aqui quando forem confirmadas. Lembre-se: a remuneração de Horas no Ar acontecem sempre na segunda sexta-feira após o fechamento de ciclo.
      </p>
    </div>
  </div>
</template>

<style lang="scss">

  #payments .content {
    padding: 0;
  }

  #payments .total {
    position: sticky;
    bottom: calc(56px + env(safe-area-inset-bottom));
    left: 0;
    right: 0;
    pointer-events: none;
    backdrop-filter: blur(4px);

    .card-background {
      opacity: .8;
      z-index: -1;
    }
  }

  #payments .items .text-truncate {
    width: 80%;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden; 
    max-width:1px;
  }

  #payments .empty-state .text {
    opacity: .75;
  }

  #payments .loading-overlay .loading {
    width: 48px;
    height: 48px;
    opacity: .8;
  }


  
</style>

<script>
  // Icons
  import { 
    mdiHelpCircleOutline, 
    mdiClose,
    mdiChevronLeft,
    mdiChevronRight,
    mdiGauge,
    mdiInformationOutline
  } from '@mdi/js';

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  // import { Scroll } from 'vuetify/lib/directives';
  // import mock from '@/assets/mock_hours.json'
  const moment = require('moment');


  export default {
    name: 'Payments',

    props: {
      user: {
        type: Object,
        default: () => null
      },
      active: {
        type: Boolean,
        default: true
      },
      dev: {
        type: Boolean,
        default: false
      },
      installed: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
        mdiChevronLeft,
        mdiChevronRight,
        mdiGauge,
        mdiInformationOutline
      },
      headers: [
        'Data',
        'Pagamento',
        'Valor',
      ],
      loader: {
        loading: false,
        updatedAt: null,
        lazyness: [15, 'minutes']
      }
    }),

    computed: {
      views: sync('app/views'),
      view: sync('app/views@payments'),
      payments: sync('payments/data@payments'),
      buzzer: sync('app/buzzer'),
      help: get('help/sections'),
      toast: sync('app/toast'),

      loading () {
        return this.loader.loading;
      },

      list () {
        return _.orderBy(this.payments, ['date'], ['desc'])
      },
    }, 

    filters: {
      paymentsTotal (payments) {
        const total = _.sumBy(payments, item => {
          const v = parseInt(item.value.split(' ')[1]);
          return v;
        });
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
      },
      formatDate (v) {
        return moment(v).format('DD/MM');
      }
    },

    methods: {
      ...services,

      // toggleView: function (b){
      //   this.view.toggle = b;
      //   // this.toggleFullscreen(b);
      // },
      // toggleFullscreen: function(b){
      //   this.view.fullscreen = b;
      // },


      // toggleHelp: function (b,c) {
      //   this.views.help.toggle = b; 
      //   this.views.help.context = typeof c != 'undefined' ? c : null;

      //   if (b) this.trackPage('help', {
      //     hash: c,
      //     title: this.help[c].title
      //   });
      // },
      
      // trackPage (page, subroute) {
      //   const route = subroute ? '/'+page+'/'+subroute.hash : '';
      //   const title = subroute ? this.views[page].title + ': ' + subroute.title : this.views[page].title;
      //   // track analytics
      //   this.$gtag.pageview({
      //     page: route,
      //     title: title + ' | Parceiros'
      //   });
      //   console.log(this.views[page].title + ' | Parceiros');
      // },

      // onScroll (e) {
      //   this.offsetTop = e.target.scrollTop;
      //   if (!this.view.fullscreen&&this.offsetTop>5) {
      //     this.toggleFullscreen(true);
      //     this.$vuetify.goTo(0, {
      //       container: this.$refs.content,
      //       duration: 500,
      //       offset: 0,
      //       easing: 'easeInOutCubic'
      //     });
      //   }
      // },

      formatType (payment) {
        const types = {
          'IND': 'Prêmio Indicação',
          'BNS': 'Prêmio Cadastro',
          'QNZ': 'Remuneração Horas no ar',
          'ACP': 'Ação Publicitária',
          'FLS': 'Flashmob',
          'CLV': 'Clube Mobees',
          'SOR': 'Prêmio Sorteio',
          'OUT': 'Outros',
        }
        const text = (payment.type in types ? types[payment.type] : 'Pagamento') + ' ' + (payment.referral!==null ? payment.referral.split(' ')[0] : '');
        return text;
      },

      getData () {
        const loader = this.loader;
        const update = this.shouldUpdate(loader);
        loader.loading = true;
        if (!update) {
          setTimeout((loader) => {
            loader.loading = false;
          }, 500, loader);
        }

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        
        this.$api
          .get('/getpayments/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getPayments => ',response);
            if(response.data.retorno==200){
              const list = JSON.parse(response.data.list);
              this.payments = [];
              for (let item in list) {
                if (typeof list[item].value!=='undefined') {
                  this.payments.push({
                    date: moment.utc(list[item].date, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD'),
                    type: list[item].type,
                    value: 'R$ '+list[item].value,
                    referral: list[item].referral,
                  });
                }
              }
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Aguardando resposta do sistema...');
              setTimeout(($, cpf, token) => {
                $.getPayments(cpf, token);
              }, 5000, this, cpf, token);
            }
            loader.updatedAt = moment().valueOf();
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },

    },

    mounted () {
      // this.setCycleChart();
      this.getData()
    },

    components: {
      MChart: () => import('@/components/mChart'),
      Loading: () => import('@/components/IconLoading'),
    },

    // directives: {
    //   Scroll,
    // },
  }
</script>
